define("discourse/plugins/user-message-channel/discourse/components/new-reviewable-modal", ["exports", "@ember/component", "@ember/component/template-only", "@ember/template-factory"], function (_exports, _component, _templateOnly, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal class="new-reviewable-post-modal"
   @title={{@model.title}}
   @closeModal={{@closeModal}}>
    <:body>
      {{@model.text}}
    </:body>
    <:footer>
      <DButton class="btn-primary"
               @translatedLabel="Ok"
               @action={{@closeModal}}
      />
    </:footer>
  </DModal>
  */
  {
    "id": "shJdmmVs",
    "block": "[[[8,[39,0],[[24,0,\"new-reviewable-post-modal\"]],[[\"@title\",\"@closeModal\"],[[30,1,[\"title\"]],[30,2]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[1,[30,1,[\"text\"]]],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,1],[[24,0,\"btn-primary\"]],[[\"@translatedLabel\",\"@action\"],[\"Ok\",[30,2]]],null],[1,\"\\n  \"]],[]]]]]],[\"@model\",\"@closeModal\"],false,[\"d-modal\",\"d-button\"]]",
    "moduleName": "discourse/plugins/user-message-channel/discourse/components/new-reviewable-modal.hbs",
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, (0, _templateOnly.default)());
});