define("discourse/plugins/user-message-channel/discourse/initializers/init-user-message-channels", ["exports", "discourse/lib/plugin-api", "discourse/lib/text", "discourse/plugins/user-message-channel/discourse/components/new-reviewable-modal", "discourse/plugins/user-message-channel/discourse/lib/notifications"], function (_exports, _pluginApi, _text, _newReviewableModal, _notifications) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = {
    name: 'init-user-message-channels',
    after: "message-bus",
    initialize(container) {
      let notifications = new _notifications.default();
      let messageBusService = container.lookup("service:message-bus");
      (0, _pluginApi.withPluginApi)("0.12.1", api => {
        api.onPageChange((url, title) => {
          let topicController = container.lookup("controller:topic");
          let topic = topicController.get('model');
          if (topic) {
            notifications.removeNotificationsByLink(`/t/${topic.get('slug')}/${topic.get('id')}`);
          }
        });
      });
      let userControllerService = container.lookup("controller:user");
      if (userControllerService?.currentUser?.id) {
        messageBusService.subscribe(`/user-messages/${userControllerService.currentUser.id}`, async data => {
          if (data?.type === 'modal') {
            let modalService = container.lookup("service:modal");
            let cookedText = await (0, _text.cook)(data.text);
            modalService.show(_newReviewableModal.default, {
              model: {
                title: data.title,
                text: cookedText
              }
            });
          }
          if (data?.type === 'pushNotification') {
            notifications.insertNotificationItem(data.text, data.title);
          }
        });
      }
    }
  };
});